.container {
  position: relative;
}
.icon {
  padding-right: 15px;
  margin-right: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  pointer-events: none;
}

.icon svg {
  width: 20px;
  height: 16px;
}

.spinner {
  width: 15px;
  height: 15px;
  font-size: 5px;
  margin-left: 20px;
}

.loadingSelect {
  padding-left: 30px;
}