.container {
  position: relative;
}


.title {
  text-align: center;
  color: #00244D;
}

.formContainer {
  background-color: #F4F4F4;
  border: 0.5px solid #c1c1c1;
  border-radius: 10px;
  margin-bottom: 60px;
  padding: 20px;
  text-align: left;
}

.colData {
  padding-top: 7.5px;
}

#datePickerElement {
  width: 130px;
  height: 31px;
  margin-top: -1.5px;
  border: solid 1px #D3D3D3;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
}

.inputTime {
  width: 130px;
}

.halfComponent {
  width: 50%;
}

.totalComponent {
  width: 100%;
}

.textComponent {
  width: 33.3%;
  width: 33.3%;
  width: 33.3%;
}

.rowButton {
  text-align: right;
}

.confirmButton {
  color: #FFF;
  background-color: #00244D;
  border-color: #042d5c;
  width: 15%;
  margin: 4px;
}

.confirmButton:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
}

.cancelButton {
  background-color: #cc140b;
  border-color: #c10020;
  color: #FFF;
  width: 15%;
  margin: 4px;
}

.cancelButton:hover {
  background-color: #FEFEFE;
  border-color: #cc140b;
  color: #cc140b;
}