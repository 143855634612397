#header {
  background-color: #00244D;
  color: #FFF;
}

.confirmButton {
  background-color: #00244D;
  border-color: #042d5c;
  color: #FFF;
  margin: 4px;
  width: 24%;
}

.confirmButton:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
}

.cancelButton {
  background-color: #cc140b;
  border-color: #c10020;
  color: #FFF;
  margin: 4px;
  width: 24%;
}

.cancelButton:hover {
  background-color: #FEFEFE;
  border-color: #cc140b;
  color: #cc140b;
}
