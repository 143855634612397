.container {
  position: relative;
}

.title {
  text-align: center;
  color: #00244D;
}

.formContainer {
  background-color: #F4F4F4;
  border: 0.5px solid #DADADA;
  border-radius: 10px;
  margin-bottom: 60px;
  padding: 20px;
  text-align: left;
}

.consultant {
  width: 25%;
}

.colDate {
  padding-top: 7.5px;
}

#datePickerElement {
  width: 130px;
  height: 31px;
  margin-top: -1.5px;
  border: solid 1px #D3D3D3;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
}

.inputTime {
  width: 130px;
}

.halfComponent {
  width: 50%;
}

.totalComponent {
  width: 100%;
}

.select {
  padding-right: 30px;
}

.icon {
  padding-right: 15px;
  margin-right: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  pointer-events: none;
}

.icon svg {
  width: 20px;
  height: 16px;
}

.spinner {
  width: 15px;
  height: 15px;
  font-size: 5px;
  margin-left: 20px;
}

.loadingSelect {
  padding-left: 30px;
}

.textComponent {
  width: 33.3%;
  width: 33.3%;
  width: 33.3%;
}

.rowButton {
  text-align: right;
}

.confirmButton {
  color: #FFF;
  background-color: #00244D;
  border-color: #042d5c;
  width: 15%;
  margin: 4px;
}

.confirmButton:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
}

.cancelButton {
  background-color: #cc140b;
  border-color: #c10020;
  color: #FFF;
  width: 15%;
  margin: 4px;
}

.cancelButton:hover {
  background-color: #FEFEFE;
  border-color: #cc140b;
  color: #cc140b;
}
