#modalHeader {
  background-color: #00244D;
  color: #FFF;
}

.modalConfirmButton {
  background-color: #00244D;
  border-color: #042d5c;
  color: #FFF;
  margin: 4px;
  width: 24%;
}

.modalConfirmButton:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
}