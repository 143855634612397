

.infoIcon {
  color: #FFF;
  position: absolute;
  top: 5%;
  font-size: 70%;
  right: 5px;
  cursor: pointer;

  &.differentColor {
    color: black;
  }
}

.tooltipBox {
  position: absolute;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  background-color: #FEFEFE;
  border: 0.5px solid #0B6CBD;
  border-radius: 10px;
  padding: 5px;
  max-width: 200px;
  margin-bottom: 10px;
  color: #00244D;

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color:  #0B6CBD transparent transparent transparent;
  }
}