.container {
  text-align: center;
  position: relative;
  max-width: 1480px;
  min-width: 600px;
  margin-bottom: 2%;
}

.title {
  margin-bottom: 10px;
  color: #00244D;
}

.divCreate {
  padding-bottom: 15px;
}

.colSelectSearch {
  display:flex; 
  justify-content: left;
  align-items: center; 
  margin-bottom: 2.5px;
}

.createButton {
  background-color: #0061ab;
  border-color: #0061a2;
}

.createButton:hover {
  background-color: #044e86;
  border-color: #000;
  color: #FFF;
}

.labelForm {
  font-weight: bold;
  margin-right: 5px;
  vertical-align: middle;
}

.selectForm {
  width: 12%;
  display: inline-block;
  margin-right: 20px;
}

.searchButton {
  text-decoration: none;
  background-color: #00244D;
  color: #FFF;
  border: none;
  margin-left: -8px;
}

.searchButton:hover {
  text-decoration: none;
  background-color: #FEFEFE;
  border: 0.5px solid #0B6CBD;
  color: #0B6CBD;
}

.tableContainer {
  margin-top: 13px;
}

.tableHeadContainer {
  text-align: center;
}

.tableHeadContainer th.tableHeadUpdate {
  border: 1px solid #0B6CBD;
  background-color: #00244D;
  position: relative;
  color: #FFF;
}

.tableBodyContainer {
  border: 1px solid #0B6CBD;
  text-align: center; 
  font-size: 80%;
}

.tableBodyContainer td.tdConsultant {
  vertical-align: middle;
  min-width: 100px;
  width: 130px;
  max-width: 150px;
}

.tableBodyContainer td.tdUpdate {
  vertical-align: middle;
  max-width: 100px;
}

.tableBodyContainer td.tdData {
  vertical-align: middle;
  min-width: 90px;
  width: 95px;
  max-width: 120px;
  color: #00244D;
}

.tableBodyContainer td.tdGoEndReturn {
  vertical-align: middle;
  min-width:80px;
  width:80px;
  max-width: 120px;
  color: #00244D;
}

.tableBodyContainer td.tdSponsor {
  width: 80px;
  vertical-align: middle;
  color: #00244D;
}

.tableBodyContainer td.tdClient {
  min-width: 90px;
  width: 200px;
  max-width: 200px;
  text-align: left;
  vertical-align: middle;
  color: #00244D;

  .classe_teste {
    display: flex;
    justify-content: center;
  }
}

.tableBodyContainer td.tdNormalHours {
  vertical-align: middle;
  min-width: 40px;
  width: 40px;
  max-width: 40px;
  color: #00244D;
}

.tableBodyContainer td.tdAditionalHours {
  vertical-align: middle;
  min-width: 50px;
  width: 60px;
  max-width: 80px;
  color: #00244D;
}

.tableBodyContainer td.tdManager {
  vertical-align: middle;
  min-width: 50px;
  width: 50px;
  max-width: 80px;
  color: #00244D;
}

.tableBodyContainer td.tdActivity {
  vertical-align: middle;
  min-width: 130px;
  width: 150px;
  max-width: 160px;
  color: #00244D;
}

.tableBodyContainer td.tdDescription {
  vertical-align: middle;
  text-align: left;
  min-width: 150px;
  width: 200px;
  max-width: 230px;
  color: #00244D;
}

.tdDescription .tdSpan {
  display: flex;
  justify-content: center;
}

.tdDescription .tdSpan span {
  width: 90%;
}

.tdStatus {
  vertical-align: middle;
  width: 130px;
  min-width: 130px;
  max-width: 130px;
}

.tableBodyContainer td.tdIcons {
  vertical-align: middle;
  min-width: 90px;
  width: 90px;
  max-width: 95px;
}

.confirmButton {
  background-color: #0061ab;
  border-color: #0061a2;
  width: 28%;
  margin: 4px;
}

.confirmButton:hover {
  background-color: #044e86;
  border-color: #000;
  color: #FFF;
}

.cancelButton {
  background-color: #cc140b;
  border-color: #c10020;
  color: #FFF;
  width: 28%;
  margin: 4px;
}

.cancelButton:hover {
  background-color: #bf140b;
  border-color: #000;
  color: #FFF;
}

.iconPencil {
  color: #000;
  margin: 3px;
  font-size: 18px;
  cursor: pointer;
}

.iconFiles {
  color: #0065b1;
  margin: 3px;
  font-size: 18px;
  cursor: pointer;
}

.iconTrash {
  color: #cc270a;
  margin: 3px;
  font-size: 18px;
  cursor: pointer;
}

.tdIconColors {
  vertical-align: middle;
  min-width: 60px;
  width: 65px;
  max-width: 65px;
}

.formStatusSelect {
  height: 40px;
  width: 180px;
  font-size: 13px;
}

.formStatusButton {
  margin-left: 5px;
  width: 80px;
  height: 39.6px;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #c1c1c1;
}

.formStatusButton:hover {
  background-color: #044e86;
  border-color: #000;
  color: #FFF;
}
