.footerContainer {
  font-size: 90%;
  border-top: 0px;
  // background-color: rgb(137, 168, 227);
  background-color: #00244D;
  border-top: 0.5px solid #c1c1c1;
  bottom: 0;
  left: 0;
  height: 40px;
  position: fixed;
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  margin-top: 26px;
  z-index: 100;
  color: #FFF;
}