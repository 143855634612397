.container {
  text-align: center;
  padding-top: 15px;
}

.title {
  text-align: center;
  color: #00244D;
}

.formContainer {
  background-color: #F4F4F4;
  border: 0.5px solid #DADADA;
  width: 500px;
  margin: auto;
  margin-top: 30px;
  padding: 10px;
  text-align: left; 
  border-radius: 10px;
}

.colContainer {
  width: 400px;
  padding-top: 10px;
}

.rowButton {
  width: 400px;
  padding-top: 20px;
  padding-bottom: 15px;
  text-align: right;
}

.labelElement {
  font-weight: bold;
  padding-top: 10px;
  color: #00244D;
}

.confirmButton {
  background-color: #00244D;
  border-color: #042d5c;
  color: #FFF;
  width: 28%;
}

.confirmButton:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
}

.cancelButton {
  background-color: #cc140b;
  border-color: #c10020;
  color: #FFF;
  width: 28%;
  margin-left: 5px;
}

.cancelButton:hover {
  background-color: #FEFEFE;
  border-color: #cc140b;
  color: #cc140b;
}