.divContent {
  padding: 0px;
  margin: 0x;
}

.header {
  display: flex;
  background-color: #00244D;
}

.item {
  flex-direction: column;
  padding-top: 0px;
  margin-left: 25px;
  margin: 5px;
}

.leftContent {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
}

.navButtons {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  align-items: center;
  padding: 2px 10px 0px 10px;
  justify-content: space-between;
}

.buttonItem {
  display: flex;
  column-gap: 10px;
}

.buttonList {
  text-decoration: none;
  background-color: #00244D;
  color: #FFF;
  border: none;
}

.buttonList:hover {
  text-decoration: none;
  background-color: #FEFEFE;
  border: 0.5px solid #0B6CBD;
  color: #0B6CBD;
}

.spanUser {
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
}

.alink{
  text-decoration: None;
  color: black
}

.alink:hover:hover{
  background-color: rgb(137, 168, 227);
  color: black
}

.logoF9c {
  width: 120px;
  height: 50px;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.logoOnSpot {
  position:absolute;
  right: 0;
  width: 110px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.botao {
  color:black
}

.link {
  color: #00244D;
  text-decoration: none;
}

.link:hover {
  color: #fff;
  background-color: #00244D;
  border-color: #042d5c;
  text-decoration: none;
}

.logout {
  color: #00244D;
  text-decoration: none;
}

.logout:hover {
  color: #fff;
  background-color: #00244D;
  border-color: #042d5c;
  text-decoration: none;
}