.mainContainer {
  padding-bottom: 1rem;
}

.title {
  text-align: center;
  color: #00244D;
}

.tableContainer {
  margin-top: 15px;
}

.tableHeadContainer {
  text-align: center;
}

.tableHeadContainer th.tableHeadElement {
  border: 1px solid #0B6CBD;
  background-color: #00244D;
  position: relative;
  color: #FFF;
}

.tableBodyContainer {
  border: 1px solid #0B6CBD;
  text-align: center;
}

.tableBodyContainer td.tableDataElement {
  font-size: 80%;
  color: #00244D;
}

.tableRowContainer {
  vertical-align: middle;
}

.labelForm {
  font-weight: bold;
  margin-right: 5px;
  vertical-align: middle;
}

.selectForm {
  text-align: left;
  width: 20%;
  display: inline-block;
  margin-right: 10px;
}

.iconPencil {
  color: #00244D;
  margin: 3px;
  font-size: 18px;
  cursor: pointer;
}

.iconTrash {
  color: #cc270a;
  margin: 3px;
  font-size: 18px;
  cursor: pointer;
}

.searchButton {
  background-color: #00244D;
  text-decoration: none;
  color: #FFF;
  border: none;
}

.searchButton:hover {
  border: 0.5px solid #0B6CBD;
  background-color: #FEFEFE;
  text-decoration: none;
  color: #0B6CBD;
}
