#time {
  border-radius: 6px;
}

#horizontalAlign {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

#checkbox {
  width: 17px;
  height: 17px;
}