.linkRegister {
  background-color: #0061ab;
  border-color: #0061a2;
  width: 12%;
  margin: 4px;
  color: #FFF;
  text-decoration: none;
}

.title {
  text-align: center;
  color: #00244D;
}

.tableContainer {
  margin-top: 15px;
  max-width: 60%;
}

.tHead {
  text-align: center;
}

.tHead th.thList {
  border: 1px solid #0B6CBD;
  background-color: #00244D;
  position: relative;
  color: #FFF;
}

.tBody {
  border: 1px solid #0B6CBD;
  text-align: center; 
  font-size: 80%;
}

.tBody td.tdList {
  width: 12%;
  vertical-align: middle;
}

.trBody {
  vertical-align: middle;
}

.iconPencil {
  color: #000;
  margin: 3px;
  font-size: 18px;
  cursor: pointer;
}

.iconTrash {
  color: #cc270a;
  margin: 3px;
  font-size: 18px;
  cursor: pointer;
}
