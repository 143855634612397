.container {
  text-align: center;
  position: relative;
  max-width: 1480px;
  position: relative;
}

.title {
  text-align: center;
  color: #00244D;
}

.labelForm {
  font-weight: bold;
  margin-right: 5px;
}

.selectForm {
  text-align: left;
  width: 12%;
  display: inline-block;
  margin-right: 10px;
}

.exportButton {
  background-color: #00244D;
  border-color: #042d5c;
  text-decoration: none;
  color: #FFF;
}

.exportButton:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
  text-decoration: none;
}

.confirmButton {
  background-color: #0061ab;
  border-color: #0061a2;
  margin-bottom: 4px;
}

.confirmButton:hover {
  background-color: #044e86;
  border-color: #000;
  color: #FFF;
}


