.title {
  text-align: center;
  color: #00244D;
}

.labelForm {
  color: #00244D;
}

.formContainer {
  background-color: #F4F4F4;
  border: 0.5px solid #DADADA;
  text-align: left; 
  width: 460px;
  margin: auto;
  margin-top: 30px;
  border-radius: 10px;
  padding: 10px;
}

.colForm {
  width: 400px;
}

.colButton {
  width: 400px;
  padding-top: 20px;
  padding-bottom: 15px;
  text-align: right;
}

.confirmButton {
  background-color: #00244D;
  border-color: #042d5c;
  color: #FFF;
  width: 28%;
}

.confirmButton:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
}

.cancelButton {
  background-color: #cc140b;
  border-color: #c10020;
  margin-left: 7px;
  color: #FFF;
  width: 28%;
}

.cancelButton:hover {
  background-color: #FEFEFE;
  border-color: #cc140b;
  color: #cc140b;
}
