.containerElement {
  height: 51.5vh;
}

#rowElement {
  width: 100%;
}

.col {
  padding-top: 30px;
}

.card {
  height: 190px;
  background-color: #F4F4F4;
  border-radius: 10px;
  border: 0.5px solid #DADADA;
}

.divCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #00244D;
}

.link {
  color: #FFF;
  background-color: #00244D;
  border-color: #042d5c;
  width: 160px;
}

.link:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
}