.mainContainer {
  height: 89vh;
  text-align: center;
  background-color: rgb(193, 206, 229);
  background-size: cover;
  background-position: center;
  // background-image: url('../../assets/background2.jpg');
}

.rowContainer {
  min-height: 80%;
}

.title {
  color: #00244D;
}

.formContainer {
  background-color: #F4F4F4;
  border: 0.5px solid #c1c1c1;
  width: 450px;
  height: 268px;
  margin: auto;
  margin-top: 30px;
  border-radius: 10px;
  padding: 10px;
  text-align: left;
}

.columnElement {
  width: 400px;
  padding-top: 10px;
}

.rowButton {
  padding: 20px;
  text-align: right;
  padding-right: 22.5px;
}

.label {
  font-weight: bold;
  padding-top: 10px;
  color: #00244D;
}

.confirmButton {
  color: #FFF;
  background-color: #00244D;
  border-color: #042d5c;
  width: 28%;
  margin: 4px;
}

.confirmButton:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
}
