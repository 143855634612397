$icon-color-green: green;
$icon-color-blue: rgb(0, 0, 142);
$icon-color-orange: orange;
$icon-color-red: red;
$text-color: #00244D;
$icon-size: 12px;

@mixin icon-styles($color) {
  color: $color;
  font-size: $icon-size;
  position: relative;
  cursor: pointer;
}

.infoIconGreenApproved {
  @include icon-styles($icon-color-green);
}

.infoIconBlocked {
  @include icon-styles($icon-color-blue);
}

.infoIconOrangePending {
  @include icon-styles($icon-color-orange);
}

.infoIconBlueRejected {
  @include icon-styles($icon-color-red);
}

@mixin tooltipBox($background-tool, $border-tool-color, $before-border-color) {
  position: absolute;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: normal;
  background-color: $background-tool;
  border: 0.5px solid $border-tool-color;
  border-radius: 10px;
  padding: 5px;
  max-width: 200px;
  margin-bottom: 7px;
  color: $text-color;

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px 10px 0;
    border-style: solid;
    border-color:  $before-border-color transparent transparent transparent;
  }
}

.tooltipBoxApproved {
  @include tooltipBox(#FEFEFE, $icon-color-green, $icon-color-green);
}
.tooltipBoxBlocked {
  @include tooltipBox(#FEFEFE, $icon-color-blue, $icon-color-blue);
}
.tooltipBoxPending {
  @include tooltipBox(#FEFEFE, $icon-color-orange, $icon-color-orange);
}
.tooltipBoxRejected {
  @include tooltipBox(#FEFEFE, $icon-color-red, $icon-color-red);
}