.container {
  height: 75vh;
}

.rowContainer {
  width: 100%;
}

.colElement {
  padding-top: 30px;
}

.cardContainer {
  background-color: #F4F4F4;
  border: 0.5px solid #DADADA;
  border-radius: 10px;
  height: 190px;
}

.cardBodyContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #00244D;
}

.buttonLink {
  background-color: #00244D;
  border-color: #042d5c;
  color: #FFF;
  width: 160px;
}

.buttonLink:hover {
  background-color: #FEFEFE;
  border-color: #0B6CBD;
  color: #0B6CBD;
}
