#tdLoading {
  position: relative;
  height: 50px ;
}

#divLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) 
}

#divLoadingSpinner {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 10px;
}

#loadingH3 {
  font-size: 20px;
}